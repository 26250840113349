// только для плагинов, которых нет в npm
import './vendor/js.cookie';
import './vendor/bvi-init';
import './vendor/bvi';
import './vendor/flatpickr.min';
import './vendor/swiper';
import './vendor/imask';
import regeneratorRuntime from "regenerator-runtime";

// этот скрипт загружает модуль для тестирования, его не должно быть в финальном билде и на проде для клиента
// import {testInstruments} from './vendor/testInstruments';


// window.addEventListener('load', () => {
//   testInstruments();
// });
